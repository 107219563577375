<template>
  <div class="gameCenter">
    <div class="head">
      <Header></Header>
    </div>
     <div class="main-card infinite-list" 
     v-infinite-scroll="loadMore" style="overflow:auto"
     v-if="gameList.length"
    >
    <div class="card-content infinite-list-item animate__animated animate__fadeIn" 
    v-for="item in gameList" :key='item.name'
    >
        <div class="content-img">
            <img :src="item.img" alt="">
        </div>
        <div class="content-txt">
          <h4>{{ item.name }}</h4>
          <p>{{ item.desc }}</p>
        </div>
        <div class="content-store" v-if="item.links.length>0" >
          <a v-for="(i,inde) in item.links" :key="inde" :href="i.url" target="_blank"><img :src="i.icon" alt=""></a>
        </div>
      </div>
    </div>
    <div class="noContent" v-else></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import { games } from '../utils/banner'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      params: {
        page: 1,
        page_size: 4,
        language: 2,
        platform:1
      },
      total:0,
      gameList: []
    }
  },
  created() {
    this.getGame()
  },
  methods: {
    getGame() {
       
          games(this.params).then(res => {
            if(res.data.status == 2000 ) {
            this.gameList.push(...res.data.data.tableData)
            this.total = res.data.data.total
            }
        })
         
    },
    loadMore() {
      if(this.gameList.length >= this.total) return;
      this.params.page++;
      this.getGame()
    },
  }
}
</script>

<style lang='scss' scope>
  .gameCenter {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .head {
    width: 100%;
    height: 2.57rem;
    background: url('../assets/img/gameCenter/banner.png') no-repeat;
    background-size: cover;
    background-position: 69% center;
  }
  .noContent {
     height: calc(100vh - 2.57rem - 150px);
  }
  .main-card {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 12rem;
    margin: 0 auto;
    .card-content {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      align-items: center;
      width: 5.714rem;
      height: 6rem;
      margin-top: 50px;
      background: #f1f1f1;
      animation: fadeIn;
      animation-duration: 2s;
      .content-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5.714rem;
        height: 3.429rem;
        // object-fit: cover;
        overflow: hidden;
        img {
          // width: 5.714rem;
          height: 100%;
          transform: scale(1);
          transition: .8s all;
        }
        img:hover{
          height: 100%;  
          transform: scale(1.1);
          transition: .8s all;
        }
      }
      .content-txt {
        height: 1.6rem;
        padding: .2667rem .9rem;
        text-align: center; 
        overflow: hidden;
        text-overflow: ellipsis;
        h4 {
          font-size: .36rem;
          margin-bottom: .1333rem;
          font-family: 'NotoSansKR-Medium';
        }
        p {
          font-size: .22rem;
          color: #626262;
          font-family: 'NotoSansKR-Regular';
          line-height: .2667rem;
        }
      }
    }
    .content-store {
      display: flex;
      a + a{
        margin-left: .35rem;
      }
      a {
        overflow: hidden;
        width: .57rem;
        height: .57rem;
        border-radius: 80px;
        box-shadow: 0 0 8px #626262;
        transform: scale(1);
        img {
          display: block;  
          width: 100%;
          height: 100%;
          // transform: scale(1.1);
        }
       
      }
       a:hover {
          transform: scale(1.15);
          transition: .2s;
        }
    }
  }
  .copy-top li a {
    color: #666972!important;
  }
  .copy-top li {
    color: #666972!important;
  }
  .copy-bottom {
    color: #666972!important;
  }

  .slide-fade-enter-active {
    transition: all 3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
 
</style>